/* eslint-disable @next/next/no-img-element */
'use client'

import { useEffect, useState } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { StaticImageData } from 'next/image';

import { phone, smallDesktop } from '@/assets/styles/themeConfig';
import { SOCIAL } from '@/utils/variables';

import FeatureTile from '@/components/FeatureTile';

import LogoSvg from "@/assets/img/logo.svg";

import RestKickstarterImg from "@/assets/img/rest-kickstarter.png";
import FacetsImg from "@/assets/img/facets-banner.png";
import ProjectSpceImg from "@/assets/img/project-space-banner.png";
import BlogImg from "@/assets/img/blog-site-banner.png";

const Main = styled.main`
  background: white;
  
  min-height: 100vh;

  .content {
    width: 100%;
    padding: 0 1rem;
    margin-top: 2rem;
    max-width: ${smallDesktop}px;
    margin: 2rem auto 0;

    .header {
      aspect-ratio: 21/9;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h1 {
        max-width: 20rem;
        margin: 4rem auto 1rem;
        width: 100%;
      }

      p {
        text-align: center;
        max-width: ${phone}px;
        margin: 0 auto;
      }

      .social {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;
        margin-bottom: 3rem;
        gap: 0 0.4rem;
        
        a {
          padding: 0.2rem;
          font-size: 1.6rem;
          color: #000000;
          opacity: 0.4;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .link-blocks {
      display: grid;
      gap: 1rem;
    }

    .footer {
      width: 100%;
      aspect-ratio: 21/6;
      display: flex;
      flex-direction: column;

      .footer-links {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;

        .split {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-bottom: 0.5rem;
          
          @media ${({ theme }) => theme.mediaQuery.smallTablet} {
            margin-bottom: 1.5rem;
          }

          .social {
            display: flex;
            align-items: center;
            justify-content: center;
            align-items: flex-end;
            gap: 0 0.4rem;
            
            a {
              padding: 0.2rem;
              font-size: 1.6rem;
              color: #000000;
              opacity: 0.4;

              &:hover {
                opacity: 1;
              }
            }
          }
        }

        .contact-us {
          color: black;
          margin-bottom: 0;
          line-height: 1;

          &:hover {
            text-decoration: underline;
          }
        }
      }


      .full-logo {
        img { 
          display: block;
        }
      }
    }
  }
`

const AnnouncementBar = styled.div<{ announcementBarVisible: boolean }>`
  position: absolute;
  z-index: 1;
  padding: 0.3rem;
  left: 0;
  right: 0;
  top: ${({ announcementBarVisible }) => announcementBarVisible ? "0rem" : "-4rem"};
  text-align: center;
  background: rgba(0, 0, 0, 1);
  color: white;
  font-size: 0.8rem;
  transition: 0.3s all 2s ease;

  span {
    text-decoration: underline;
    cursor: pointer;
  }
`

export type LinkData = {
  hidden?: boolean;
  title: string;
  img: StaticImageData | string;
  description?: string;
  link: string;
}

const links_data: LinkData[] = [
  {
    title: 'Youtube - Latest Video',
    link: "https://youtu.be/Y6EQ0M8xDKI?si=NEJrvr-3IQB_nn4j",
    img: "https://img.youtube.com/vi/Y6EQ0M8xDKI/maxresdefault.jpg"
  },
  {
    title: 'Wallpapers',
    link: "https://plaen.gumroad.com?ref=plaenco",
    img: FacetsImg
  },
  {
    title: 'Blog',
    link: "/blog",
    img: BlogImg
  },
  {
    title: 'Rest (Kickstarter)',
    link: "https://www.kickstarter.com/projects/rohanbhangui/rest-the-perfect-solution-to-end-your-daily-clutter?ref=8n33n7",
    img: RestKickstarterImg
  },
  
  {
    title: 'Project Spce',
    link: "https://projectspce.com?ref=plaenco",
    img:ProjectSpceImg
  },
]

const Home = () => {
  const [announcementBarVisible, setAnnouncementBarVisible] = useState(false);

  useEffect(() => {
    setAnnouncementBarVisible(true);

    return () => {
      setAnnouncementBarVisible(false);
    }
  }, [])

  const openSignUp = () => {
    window.klaviyo = window.klaviyo || [];
    window.klaviyo.push(['openForm', 'WvWUqX']);
  }

  return (
    <Main>
      <AnnouncementBar announcementBarVisible={announcementBarVisible}>
        Want the latest news? <span onClick={openSignUp}>Subscribe today</span>
      </AnnouncementBar>
      <div className="content">
        <div className="header">
          <h1>
            <svg id="a" viewBox="0 0 99.48 17.08">
              <g fill="#000000">
                <path d="M9.59.74C8.7.25 7.58 0 6.26 0H0v17.08h3.17v-5.76h3.08c1.32 0 2.43-.25 3.33-.74a4.95 4.95 0 0 0 2.04-2.04c.45-.85.68-1.82.68-2.89s-.23-2.04-.67-2.88c-.45-.86-1.13-1.54-2.02-2.03ZM3.17 2.76h2.76c.76 0 1.38.13 1.84.38.45.24.79.58 1 1 .22.43.33.95.33 1.52s-.11 1.09-.33 1.53c-.21.43-.54.77-.99 1.02-.45.25-1.07.38-1.82.38H3.17V2.76Z"></path>
                <polygon points="24.62 0 21.45 0 21.45 17.08 32.06 17.08 32.06 14.31 24.62 14.31 24.62 0"></polygon>
                <path d="m46.73 0-6.11 17.08h3.37l1.47-4.29h6.04l1.47 4.29h3.37L50.23 0h-3.5Zm3.84 10.09h-4.19l2.09-6.12 2.1 6.12Z"></path>
                <polygon points="68.07 9.92 75.38 9.92 75.38 7.14 68.07 7.14 68.07 2.77 75.92 2.77 75.92 0 64.9 0 64.9 17.08 76 17.08 76 14.31 68.07 14.31 68.07 9.92"></polygon>
                <polygon points="96.34 0 96.34 11.56 88.42 .11 88.34 0 85.52 0 85.52 17.08 88.69 17.08 88.69 5.54 96.68 17.08 99.48 17.08 99.48 0 96.34 0"></polygon>
              </g>
            </svg>
          </h1>
          <p>Make everyday products built with purposeful utility and clean design that inspire people to take on the day.</p>
          <div className="social">
            {SOCIAL.map(({url, icon}, index) => (
              <Link key={index} href={url}>
                <i aria-hidden className={`fab ${icon}`} />
              </Link>
            ))}
          </div>
        </div>
        <div className="big-links">
          <div className="link-blocks">
            {
              links_data.filter(item => !item.hidden).map(({ title, ...rest }) => (
                <FeatureTile key={title} title={title} {...rest} />
              ))
            }
          </div>
        </div>

        <div className="footer">
          <div className="footer-links">
            <div className="split">
              <Link href="mailto:hello@plaen.co" className="contact-us h4">
                hello@plaen.co
              </Link>
              <div className="social">
                #ShareASale 
                {SOCIAL.map(({url, icon}, index) => (
                  <Link key={index} href={url}>
                    <i aria-hidden className={`fab ${icon}`} />
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className="full-logo">
            <img src={LogoSvg.src} alt="P L A E N" />
          </div>
        </div>
      </div>
    </Main>
  )
}

export default Home;
